import { ProviderType } from '@mwell-healthhub/commons';

const iconDental = '/static-assets/images/providers/MHH-Dental.svg';
const iconDiagnostic = '/static-assets/images/providers/MHH-Diagnostic.svg';
const iconEye = '/static-assets/images/providers/MHH-Eye.svg';
const iconHair = '/static-assets/images/providers/MHH-Hair.svg';
const iconMaternity = '/static-assets/images/providers/MHH-Maternity.svg';
const iconMedical = '/static-assets/images/providers/MHH-Medical.svg';
const iconSkin = '/static-assets/images/providers/MHH-Skin.svg';
const iconTherapy = '/static-assets/images/providers/MHH-Therapy.svg';
const iconWellnessSpa = '/static-assets/images/providers/MHH-Wellness-Spa.svg';

import { Routes } from './routes';

export type ServiceData = {
  icon: string;
  label: string;
  link: string;
  name: string;
};

// Source of Truth: If you want to add a new provider icon to display, add it here.

export const SERVICES: ServiceData[] = [
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.MEDICAL}`,
    name: ProviderType.MEDICAL,
    icon: iconMedical,
    label: 'Medical',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.DIAGNOSTIC}`,
    name: ProviderType.DIAGNOSTIC,
    icon: iconDiagnostic,
    label: 'Diagnostic',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.DENTAL}`,
    name: ProviderType.DENTAL,
    icon: iconDental,
    label: 'Dental',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.EYE}`,
    name: ProviderType.EYE,
    icon: iconEye,
    label: 'Eye',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.SKIN}`,
    name: ProviderType.SKIN,
    icon: iconSkin,
    label: 'Skin',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.MATERNITY}`,
    name: ProviderType.MATERNITY,
    icon: iconMaternity,
    label: 'Maternity',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.THERAPY}`,
    name: ProviderType.THERAPY,
    icon: iconTherapy,
    label: 'Therapy',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.WELLNESS_SPA}`,
    name: ProviderType.WELLNESS_SPA,
    icon: iconWellnessSpa,
    label: 'Wellness Spa',
  },
  {
    link: `${Routes.PROVIDERS}/types/${ProviderType.HAIR}`,
    name: ProviderType.HAIR,
    icon: iconHair,
    label: 'Hair',
  },
];
