import { SearchFieldHome } from '@mwell-healthhub/commons';

type Props = {
  onFocus: (evt: React.FocusEvent<HTMLInputElement, Element>) => void;
};

const Search = (props: Props) => {
  const { onFocus } = props;

  return (
    <section className="w-full bg-white">
      <SearchFieldHome
        placeholder="What are you booking today?"
        roundClass="rounded-full"
        className="w-full flex-auto grow"
        onFocus={onFocus}
      />
    </section>
  );
};

export default Search;
