import React, { Fragment, useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import {
  ALL,
  AutoComplete,
  AutoCompleteOptionType,
  Button,
  MultiSelectOptionType,
  Option,
  generateAutoCompleteOptions,
  useRouter,
} from '@mwell-healthhub/commons';
import SearchField from '@mwell-healthhub/commons/components/SearchFieldHome/SearchField';
import { trackEvent, EventName } from '@mwell-healthhub/commons/utils/analytics';

import HmoFilter from './HmoFilter';
import { Routes } from '../../constants/routes';

type Props = {
  defaultInputValue?: string;
  isLoading?: boolean;
  handleProviderTypeSelection?: (providerType: string) => void;
  appliedHmoFilters?: MultiSelectOptionType[];
  handleApplyHmoFilters?: (hmoFilters: MultiSelectOptionType[]) => void;
  showProviderTypeSelection?: boolean;
  defaultSelectedProviderType?: Option;
  dropDownOptions?: Option[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const HomeSearch = (props: Props) => {
  const {
    defaultSelectedProviderType,
    defaultInputValue = '',
    isLoading = false,
    handleProviderTypeSelection,
    appliedHmoFilters,
    handleApplyHmoFilters,
    showProviderTypeSelection = false,
    dropDownOptions = [{ value: '', label: 'All Provider Types' }],
    onChange,
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>(defaultInputValue);
  const [selectedProviderType, setSelectedProviderType] = useState<Option | undefined>(
    defaultSelectedProviderType,
  );
  const router = useRouter();
  const { query } = router;

  const isInDropdownOption = dropDownOptions.some(
    (opt) => opt.value === defaultSelectedProviderType?.value,
  );

  const options = generateAutoCompleteOptions(
    [
      ...dropDownOptions.map((option) => {
        return { id: option.value || ALL, name: option.label };
      }),
    ],
    {},
  );

  useEffect(() => {
    if (defaultSelectedProviderType !== selectedProviderType && isInDropdownOption) {
      setSelectedProviderType(defaultSelectedProviderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedProviderType, isInDropdownOption]);

  function handleSearch(): void {
    if (!searchTerm.trim()) {
      return;
    }

    delete query.path;

    trackEvent(EventName.SEARCH, {
      search_term: searchTerm,
    });

    const currentPathname = window.location.pathname;
    const pathname = currentPathname.includes('/types/') ? currentPathname : Routes.PROVIDERS;

    router.replace({
      pathname,
      query: { ...query, query: searchTerm },
    });
  }

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValue = event.target.value;

    setSearchTerm(newValue);
    onChange && onChange(event);
  }

  const handleOnProviderTypeSelectionChange = (option: AutoCompleteOptionType) => {
    const value: Option = { value: option.id === ALL ? '' : option.id, label: option.text };
    setSelectedProviderType(value);
    handleProviderTypeSelection?.(value.value);
  };

  const handleOnKeyup = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const searchPlaceholder = selectedProviderType
    ? `Search for ${selectedProviderType.label} clinics and services`
    : 'Search (eg. Facial, Teeth Cleaning, Massage...)';

  return (
    <Fragment>
      <div className="flex w-full items-center justify-center gap-x-3 gap-y-3 rounded-2xl bg-neutral-100 p-3 drop-shadow xl:flex-row">
        <div className="w-full grow basis-full">
          <SearchField
            className="w-full rounded-none"
            placeholder={searchPlaceholder}
            height="h-[52px]"
            roundClass="rounded-lg"
            onChange={handleChangeSearch}
            defaultValue={defaultInputValue}
            onKeyUp={handleOnKeyup}
          />
        </div>
        {showProviderTypeSelection && (
          <div className="w-full grow basis-2">
            <AutoComplete
              inputClass="w-full min-w-[200px] rounded-xl rounded-lg ring-1 ring-inset ring-neutral-300 p-3.5"
              isSearchEnabled
              options={options}
              placeholder="Provider Type"
              onSelectedItem={handleOnProviderTypeSelectionChange}
            />
          </div>
        )}
        {handleApplyHmoFilters && (
          <HmoFilter
            appliedHmoFilters={appliedHmoFilters as MultiSelectOptionType[]}
            handleApplyHmoFilters={handleApplyHmoFilters}
          />
        )}
        <div
          className={twMerge(
            'flex gap-x-3',
            showProviderTypeSelection ? 'w-full grow basis-3' : 'grow',
          )}
        >
          <Button
            isLoading={isLoading}
            variant="primary"
            className="h-[52px] min-w-[240px] rounded-xl"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default HomeSearch;
