import '@fortawesome/fontawesome-free/css/all.min.css';
import { useState } from 'react';

import { Appointment } from '@healthhub/api-lib';
import { filter } from 'lodash';
import { GetServerSideProps } from 'next';
import Head from 'next/head';

import {
  LoadingSpinner,
  MultiSelectOptionType,
  checkVerification,
  fetchCurrentUserNextAppointment,
  useRouter,
} from '@mwell-healthhub/commons';

import { HmoFilter, HmoFilterBadges } from '../components';
import PromotionCard from '../components/molecules/PromotionCard';
import Advertisement from '../components/organisms/Home/Advertisement';
import BookingHeader from '../components/organisms/Home/BookingHeader';
import BottomNavigation from '../components/organisms/Home/BottomNavigation';
import HomeContent from '../components/organisms/Home/HomeContent';
import NextAppointment from '../components/organisms/Home/NextAppointment';
import Search from '../components/organisms/Home/Search';
import ServiceAction from '../components/organisms/Home/ServiceActions';
import SubmitProvider from '../components/organisms/Home/SubmitProvider';
import VerifyEmail from '../components/organisms/Home/VerifyEmail';
import { HomeLayout } from '../components/templates';
import { Routes } from '../constants';
import { SHOW_VERIFY_EMAIL_FUNCTIONALITY } from '../constants/featureFlags';
import { useIsMobile } from '../hooks';
import { MWELL_LOGIN_PARAMS } from '../utils';

type Props = {
  isVerified: boolean | null;
  upcomingAppointment?: Appointment | null;
  isUserLoggedIn: boolean;
};

export function Index({ isVerified, upcomingAppointment, isUserLoggedIn }: Props) {
  const router = useRouter();
  const { query } = router;
  const [hmoFilters, setHmoFilters] = useState<MultiSelectOptionType[]>([]);
  const isMobile = useIsMobile();

  const isInitializingLoginKeys = 'token' in query;

  const showVerifyEmail = isUserLoggedIn && isVerified === false && SHOW_VERIFY_EMAIL_FUNCTIONALITY;

  function handleFocus() {
    router.push(Routes.PROVIDERS);
  }

  const handleApplyHmoFilters = (selectedHmoFilters: MultiSelectOptionType[]) => {
    if (selectedHmoFilters === hmoFilters) {
      return;
    }
    setHmoFilters(selectedHmoFilters);
    delete query.path;

    router.replace({
      pathname: Routes.PROVIDERS,
      query: { ...query, hmoFilters: selectedHmoFilters.map(({ value }) => value) },
    });
  };

  const onRemoveHmoFilter = (hmo: MultiSelectOptionType) => {
    let updatedHmoFilters: MultiSelectOptionType[] = [];
    setHmoFilters((hmoFilters) => {
      updatedHmoFilters = filter(hmoFilters, ({ value }) => value !== hmo.value);
      handleApplyHmoFilters(updatedHmoFilters);
      return updatedHmoFilters;
    });
  };

  const mobileView = (
    <HomeLayout>
      {isInitializingLoginKeys ? (
        <div className="flex min-h-screen items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {showVerifyEmail && <VerifyEmail />}
          <div className="mb-[76px] h-full min-h-screen bg-white px-6 py-5">
            <section className="flex flex-col gap-y-5 sm:overflow-y-auto">
              <div className="flex flex-row flex-nowrap items-center gap-x-2">
                <Search onFocus={handleFocus} />
                <HmoFilter
                  appliedHmoFilters={hmoFilters}
                  handleApplyHmoFilters={handleApplyHmoFilters}
                  className="w-auto flex-1"
                />
              </div>
              {!!hmoFilters.length && (
                <HmoFilterBadges
                  appliedHmoFilters={hmoFilters}
                  onRemoveHmoFilter={onRemoveHmoFilter}
                />
              )}
              <ServiceAction />
              {isUserLoggedIn && <NextAppointment appointment={upcomingAppointment} />}
              <Advertisement />
              <PromotionCard />
            </section>
          </div>
          <BottomNavigation activeTab="home" isUserLoggedIn={isUserLoggedIn} />
        </>
      )}
    </HomeLayout>
  );

  const desktopView = (
    <HomeLayout shouldWhiteBackground>
      <div className="flex flex-col overscroll-none">
        <BookingHeader />
        <HomeContent />
        <SubmitProvider />
      </div>
    </HomeLayout>
  );

  return (
    <>
      <Head>
        <title>Home | HealthHub</title>
        <meta
          name="description"
          content="HealthHub by MWell Philippines is your go-to platform for finding and booking appointments with top-tier health and wellness providers. Explore our listing of trusted professionals and service providers offering a wide array of services, from medical consultations to holistic wellness therapies. Seamlessly schedule appointments online and take control of your health journey. Start prioritizing your well-being today with HealthHub."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      {isMobile ? mobileView : desktopView}
    </>
  );
}

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  try {
    const { req } = context;
    const { cookie } = context.req.headers;
    const accessToken = req.cookies.access_token;

    if (!accessToken) {
      return {
        props: {
          isVerified: null,
          upcomingAppointment: null,
          isUserLoggedIn: false,
        },
      };
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const [isVerified, upcomingAppointment] = await Promise.all([
      SHOW_VERIFY_EMAIL_FUNCTIONALITY ? checkVerification(config) : null,
      fetchCurrentUserNextAppointment({
        headers: {
          Cookie: cookie,
        },
      } as RequestInit),
    ]);

    return {
      props: {
        isVerified: isVerified ?? null,
        upcomingAppointment: upcomingAppointment ?? null,
        isUserLoggedIn: true,
      },
    };
  } catch (err) {
    console.error(err);

    return {
      props: {
        isVerified: null,
        upcomingAppointment: null,
        isUserLoggedIn: false,
      },
    };
  }
};

export default Index;
