import React, { Fragment } from 'react';

import Link from 'next/link';

const appStoreImageUrl = '/static-assets/images/app-store.webp';
const googlePlayImageUrl = '/static-assets/images/google-play.webp';
const phoneImageUrl = '/static-assets/images/phone.webp';

const DownloadCard = () => {
  return (
    <Fragment>
      <div className="relative flex h-[411px] w-full items-center overflow-hidden rounded-2xl bg-ad-background-gradient px-[68px]">
        <div className="z-10 flex w-[496px] flex-col">
          <h1 className="mb-6 font-semibold leading-normal tracking-tight text-secondary-100 md:text-[32px]">
            Book Your Appointment on Top Dental Clinics, Skin Clinics and Spas Now.
          </h1>
          <p className="mb-8 text-base font-normal text-white md:text-lg">
            Available on both App Store and Google Play Store
          </p>
          <div className="flex gap-[11px]">
            <Link
              target="_blank"
              href="https://apps.apple.com/ph/app/mwell-ph-24-7-doctor-consult/id1540729485?mt=8"
            >
              <img src={appStoreImageUrl} alt="App Store" width="140" height="51" />
            </Link>
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.lf.mpicph&pcampaignid=web_share"
            >
              <img src={googlePlayImageUrl} alt="Google Play" width="140" height="51" />
            </Link>
          </div>
        </div>
        <div className="hidden lg:absolute lg:bottom-[-65px] lg:right-[100px] lg:block">
          <img src={phoneImageUrl} alt="Phone" width="300" height="455" />
        </div>
      </div>
    </Fragment>
  );
};

export default DownloadCard;
