const appointments = '/static-assets/images/navigation/Appointments-Active.svg';
const appointmentsInactive = '/static-assets/images/navigation/Appointments-Inactive.svg';
const home = '/static-assets/images/navigation/Home-Active.svg';
const homeInactive = '/static-assets/images/navigation/Home-Inactive.svg';
const profile = '/static-assets/images/navigation/Profile-Active.svg';
const profileInactive = '/static-assets/images/navigation/Profile-Inactive.svg';
const record = '/static-assets/images/navigation/Record-Active.svg';
const recordInactive = '/static-assets/images/navigation/Record-Inactive.svg';
const messages = '/static-assets/images/navigation/messages-active.svg';
const messagesInactive = '/static-assets/images/navigation/messages-inactive.svg';

import { SHOW_MEDICAL_RECORDS, SHOW_MESSAGE_THREADS } from '@mwell-healthhub/commons';

import { Routes } from './routes';

export type NavData = {
  icon: string;
  inactiveIcon?: string;
  label: string;
  name: string;
  url: string;
  hidden?: boolean;
};

export const BOTTOM_NAVS: NavData[] = [
  {
    name: 'home',
    icon: home,
    inactiveIcon: homeInactive,
    label: 'Home',
    url: Routes.ROOT,
  },
  {
    name: 'appointments',
    icon: appointments,
    inactiveIcon: appointmentsInactive,
    label: 'Appointments',
    url: Routes.APPOINTMENTS,
  },
];

export const LOGGED_IN_BOTTOM_NAVS: NavData[] = [
  ...BOTTOM_NAVS,
  {
    name: 'records',
    icon: record,
    inactiveIcon: recordInactive,
    label: 'Records',
    url: Routes.RECORDS,
    hidden: !SHOW_MEDICAL_RECORDS,
  },
  {
    name: 'messages',
    icon: messages,
    inactiveIcon: messagesInactive,
    label: 'Messages',
    url: Routes.MESSAGES,
    hidden: !SHOW_MESSAGE_THREADS,
  },
  {
    name: 'profile',
    icon: profile,
    inactiveIcon: profileInactive,
    label: 'Profile',
    url: Routes.PROFILE,
  },
];

export const NOT_LOGGED_IN_BOTTOM_NAVS: NavData[] = [
  ...BOTTOM_NAVS,
  {
    name: 'login',
    icon: profile,
    inactiveIcon: profileInactive,
    label: 'Login',
    url: Routes.AUTH,
  },
];
