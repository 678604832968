import React, { Fragment } from 'react';

type Props = {
  imageUrl: string;
  title: string;
  description: string;
};

const DescriptionCard = ({ imageUrl, title, description }: Props) => {
  const cardStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <Fragment>
      <div className="flex w-[350px] flex-col gap-y-6">
        <div
          className="w-full rounded-lg bg-cover bg-local bg-no-repeat sm:h-[140px] md:h-[180px] lg:h-[257px]"
          style={cardStyle}
        />
        <div className="flex grow flex-col gap-y-[11px]">
          <h1 className=" text-lg font-semibold text-neutral-600 lg:text-2xl">{title}</h1>
          <p className="font-regular text-sm text-neutral-600 lg:text-base lg:font-medium">
            {description}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default DescriptionCard;
