import { ClientProfile } from '@healthhub/api-lib';

import { useGetUser } from '../../hooks/user/useGetUser';

type Props = {
  clientProfileIds: number[];
};

function ListSelectedClientGroupBooking(props: Props) {
  const { clientProfileIds } = props;

  const { user } = useGetUser();

  const getYouLabel = (clientProfile: ClientProfile) => {
    if (clientProfile.id.toString() === user?.mainProfile.id.toString()) {
      return '(You)';
    }

    return '';
  };

  function getSelectedClientProfilesByIds(clientProfileIds: number[]): ClientProfile[] | undefined {
    const clientProfileArray = user?.clientProfiles || [];

    const filteredProfiles = clientProfileArray?.filter((cp) => clientProfileIds.includes(+cp.id));

    return filteredProfiles;
  }

  return (
    <ul className="space-y-1 text-sm font-medium leading-tight tracking-tight text-gray-600 md:space-y-2">
      {getSelectedClientProfilesByIds(clientProfileIds)?.map((profile) => (
        <li key={profile.id}>{`${profile.firstName} ${profile.lastName} ${getYouLabel(
          profile,
        )}`}</li>
      ))}
    </ul>
  );
}

export default ListSelectedClientGroupBooking;
