import React, { Fragment } from 'react';

import { Icon } from '@mwell-healthhub/commons';

type Props = {
  imageUrl: string;
  iconUrl: string;
  title: string;
  imgPosition: string;
};

const ServiceCard = ({ imageUrl, iconUrl, title, imgPosition }: Props) => {
  return (
    <Fragment>
      <div className="h-full min-h-[118px] w-full min-w-[92px] items-center sm:w-[110px]">
        <div className="flex h-full w-full flex-col items-center gap-y-2">
          <div className="flex h-[90px] w-full flex-col items-center justify-center rounded-2xl rounded-tr-none bg-lightCyan">
            <Icon image={iconUrl} alt={title} height={44} width={44} />
          </div>
          <h1 className="text-center text-sm font-medium text-primary-500">{title}</h1>
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceCard;
