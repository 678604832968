import slugify from 'slugify';

import { ProviderType } from '@mwell-healthhub/commons';

import { Routes } from './routes';

export type ServiceCardType = {
  link: string;
  imageUrl: string;
  iconUrl: string;
  title: string;
  imgPosition: string;
};

export const SERVICE_CARD_DATA: ServiceCardType[] = [
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.MEDICAL)}`,
    imageUrl: '/static-assets/images/home/medical-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Medical.svg',
    title: 'Medical',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.DIAGNOSTIC)}`,
    imageUrl: '/static-assets/images/home/diagnostic-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Diagnostic.svg',
    title: 'Diagnostic',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.DENTAL)}`,
    imageUrl: '/static-assets/images/home/dental-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Dental.svg',
    title: 'Dental',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.EYE)}`,
    imageUrl: '/static-assets/images/home/eye-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Eye.svg',
    title: 'Eye',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.SKIN)}`,
    imageUrl: '/static-assets/images/home/skin-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Skin.svg',
    title: 'Skin',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.MATERNITY)}`,
    imageUrl: '/static-assets/images/home/maternity-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Maternity.svg',
    title: 'Maternity',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.THERAPY)}`,
    imageUrl: '/static-assets/images/home/therapy-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Therapy.svg',
    title: 'Therapy',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.WELLNESS_SPA)}`,
    imageUrl: '/static-assets/images/home/spa-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Spa.svg',
    title: 'Wellness Spa',
    imgPosition: '',
  },
  {
    link: `${Routes.PROVIDERS}/types/${slugify(ProviderType.HAIR)}`,
    imageUrl: '/static-assets/images/home/hair-image.webp',
    iconUrl: '/static-assets/images/home/MHH-Hair.svg',
    title: 'Hair',
    imgPosition: '',
  },
];
