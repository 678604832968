export const DESCRIPTION_CARD_DATA = [
  {
    title: 'Effortless Booking for All',
    description: 'Schedule appointments with ease for yourself or your loved ones.',
    imageUrl: '/static-assets/images/effortless-image.webp',
  },
  {
    title: 'Discover Health Services Fast',
    description:
      'Quickly locate dental, skin, and wellness clinics, and explore other specialized health and wellness services near you.',
    imageUrl: '/static-assets/images/Massage.jpg',
  },
  {
    title: 'Simplified Registration Process',
    description:
      'Go to any mWell Healthhub partner and easily register by simply scanning their QR code.',
    imageUrl: '/static-assets/images/simplified-image.webp',
  },
];
