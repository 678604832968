import React, { ChangeEvent, Fragment, useState } from 'react';

import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

import {
  Button,
  Dropdown,
  Option,
  TextInput,
  convertObjectsToOptions,
  toast,
  useGetPSGCCitiesMunicipalities,
  useGetPSGCProvinces,
} from '@mwell-healthhub/commons';
import { suggestProvider } from '@mwell-healthhub/commons';
import { alphanumericWithPeriodCommaDashAmpersand } from '@mwell-healthhub/commons';

import DoctorVector from '../../../public/static-assets/images/doctor-vector.png';

const SubmitProvider = () => {
  const { data: citiesData } = useGetPSGCCitiesMunicipalities();
  const { data: provincesData } = useGetPSGCProvinces();

  const [searchQuery, setSearchQuery] = useState('');

  const getCityProvinceName = (cityCode: string) => {
    const city = citiesData?.find((city) => city.code === cityCode);
    const province = provincesData?.find((province) => province.code === city?.provinceCode);
    return `${city?.name}${province?.name ? `, ${province.name}` : ''}`;
  };

  const cities = citiesData
    ?.filter((city) => city.isCity)
    .map((city) => ({ name: getCityProvinceName(city.code), id: city.code }))
    .filter((city) => city.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => (a.name < b.name ? -1 : 1));
  const options = convertObjectsToOptions(cities || [], {
    includeNone: false,
  });

  const isDropdownDisabled = !cities?.length;
  const [selectedCity, setSelectedCity] = useState<Option>();
  const [providerName, setProviderName] = useState('');
  const isSubmitDisabled = !selectedCity || !providerName;
  const [errorMessage, setErrorMessage] = useState('');

  const handleCityChange = (value: Option) => {
    setSelectedCity(value);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const value = event.target.value;

    if (value && !alphanumericWithPeriodCommaDashAmpersand.test(value)) {
      setErrorMessage('Text contains forbidden characters');
    }

    setProviderName(value);
  };

  const handleOnSubmit = async () => {
    setErrorMessage('');

    if (!selectedCity?.label || !providerName) {
      return;
    }

    const trimmedProviderName = providerName.trim();

    if (!trimmedProviderName) {
      setProviderName('');
      setErrorMessage('Please provide a clinic/health provider name');
      return;
    }

    if (!alphanumericWithPeriodCommaDashAmpersand.test(trimmedProviderName)) {
      setErrorMessage('Text contains forbidden characters');
      return;
    }

    await suggestProvider({
      providerName: trimmedProviderName,
      cityName: selectedCity?.label,
    });

    toast({ message: 'Provider suggestion sent!', type: 'success' });
    setProviderName('');
    setSelectedCity(undefined);
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value.trim());
  };

  return (
    <Fragment>
      <div className="relative flex h-[530px] w-full items-center justify-center overflow-hidden bg-neutral-100">
        <div className="flex w-[1110px] justify-center lg:justify-start">
          <div className="flex w-[635px] flex-col items-center  gap-y-10 lg:items-start lg:pl-20 xl:pl-0">
            <div className="flex flex-col text-center lg:text-left">
              <h1 className="mb-6 text-[32px] font-semibold text-neutral-600">
                Is there a specific health provider you&apos;d like to see on our platform?
              </h1>
              <p className="font-regular text-base text-neutral-500">
                Let us know who you&apos;d like to see added by filling out our suggestion form.
              </p>
            </div>

            <div className="flex w-[345px] flex-col">
              <div className="mb-4 flex flex-col gap-y-1.5">
                <p className="font-regular text-sm text-neutral-600">City</p>
                <Dropdown
                  options={options}
                  placeholder="Select City"
                  disabled={isDropdownDisabled}
                  hasSearchInput
                  className={twMerge(isDropdownDisabled && 'cursor-not-allowed')}
                  optionsClassName="max-h-[150px]"
                  value={selectedCity}
                  onChange={handleCityChange}
                  onSearchInputChange={handleSearchInputChange}
                />
              </div>
              <div className="mb-8 flex flex-col gap-y-1.5">
                <p className="font-regular text-sm text-neutral-600">Clinic/Health Provider Name</p>
                <TextInput
                  placeholder="Enter clinic/health provider name"
                  onChange={handleNameChange}
                  errorMessage={errorMessage}
                  value={providerName}
                />
              </div>
              <Button
                disabled={isSubmitDisabled}
                variant="primary"
                className="h-[52px]"
                onClick={handleOnSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>

        <div className="hidden lg:absolute lg:-right-48 lg:block xl:right-0">
          <Image src={DoctorVector} alt="" />
        </div>
      </div>
    </Fragment>
  );
};

export default SubmitProvider;
