import { HeartIcon } from '@heroicons/react/20/solid';

import { useIsMobile } from '../../hooks';

const FavouriteBadge = () => {
  const isMobile = useIsMobile();

  const mobileView = (
    <div className="flex px-1 md:mx-0 md:flex-wrap ">
      <span className="border-box mt-2 flex w-auto flex-nowrap gap-x-1.5 whitespace-nowrap rounded-md border bg-red-500 py-1 pl-2 pr-2 text-xs font-medium text-neutral-50">
        <HeartIcon width={16} height={16} className="text-neutral-50" />
      </span>
    </div>
  );

  const desktopView = (
    <div className="flex px-1 md:mx-0 md:flex-wrap ">
      <span className="border-box flex w-auto flex-nowrap gap-x-1.5 whitespace-nowrap rounded-md border bg-red-500 py-1 pl-2 pr-2 text-xs font-medium text-neutral-50">
        <HeartIcon width={16} height={16} className="text-neutral-50" />
        Favorite
      </span>
    </div>
  );

  return <div>{isMobile ? mobileView : desktopView}</div>;
};

export default FavouriteBadge;
