import { useMemo } from 'react';

import {
  AppointmentApprovalStatusEnum,
  AppointmentStatusEnum,
  Transaction,
} from '@healthhub/api-lib';
import { MapPinIcon, UserCircleIcon } from '@heroicons/react/20/solid';

import {
  APPOINTMENT_STATUS_BADGE_COLOR,
  APPOINTMENT_STATUS_USER_DISPLAY,
  BadgeType,
  SHOW_FEEDBACK_FEATURE,
  SHOW_GROUP_BOOKING,
  clsxMerge,
  useRouter,
} from '@mwell-healthhub/commons';
import { Badge, Button } from '@mwell-healthhub/components/v2';

import BookingPaymentDetails from './BookingPaymentDetails';
import { Routes } from '../../constants';
import { BookingPaymentDetailsPropValues } from '../../contexts/provider';
import ListSelectedClientGroupBooking from '../atoms/ListSelectedClientGroupBooking';

type Props = {
  appointmentId?: string;
  appointmentSchedule: string;
  approvalStatus?: AppointmentApprovalStatusEnum;
  branchId?: string;
  hasFeedback?: boolean;
  patientName: string;
  providerAddress: string;
  providerName: string;
  services: string;
  shouldHideFooterAction?: boolean;
  showBadge?: boolean;
  status: string;
  transaction?: Transaction;
  clientProfileIds?: number[];
  onClick: () => void;
};

type ActionFooterProps = {
  appointmentId?: string;
  branchId?: string;
  hasFeedback?: boolean;
  shouldHideFooterAction?: boolean;
  status: string;
  handleClickViewDetails: () => void;
};

export default function AppointmentDetailCard(props: Props) {
  const {
    appointmentId,
    appointmentSchedule,
    approvalStatus,
    branchId,
    hasFeedback,
    patientName,
    providerAddress,
    providerName,
    services,
    shouldHideFooterAction = false,
    showBadge = true,
    status,
    transaction,
    clientProfileIds = [],
    onClick,
  } = props;
  const externalPaymentDetails = transaction?.externalPaymentDetails as any;
  const paymentDetails: BookingPaymentDetailsPropValues | null = externalPaymentDetails
    ? {
        paymentDate: externalPaymentDetails.paymentDetails
          ? externalPaymentDetails.paymentDetails.paymentAt
          : transaction?.createdAt,
        paymentStatus: externalPaymentDetails.paymentStatus ?? transaction?.status,
        transactionNo: externalPaymentDetails.receiptNumber,
        totalAmountDue: Number(externalPaymentDetails.totalAmount?.value) * 100,
        paymentMethod: externalPaymentDetails.paymentDetails
          ? externalPaymentDetails.paymentDetails.maskedCardNumber
          : externalPaymentDetails.paymentScheme,
      }
    : null;
  const numberOfClientsBooked =
    clientProfileIds.length > 1 ? `${clientProfileIds.length} clients` : patientName;

  const handleClick = () => {
    if (shouldHideFooterAction) {
      return;
    }

    onClick();
  };

  const isAppointmentApprovalStatusPending =
    approvalStatus === AppointmentApprovalStatusEnum.Pending;
  const isAppointmentApprovalStatusDeclined =
    approvalStatus === AppointmentApprovalStatusEnum.Declined;
  const isCancelled = status === AppointmentStatusEnum.Cancelled;

  const appointmentStatusDisplay = useMemo(() => {
    if (isAppointmentApprovalStatusDeclined) {
      return 'Declined';
    }
    if (isCancelled) {
      return APPOINTMENT_STATUS_USER_DISPLAY[status];
    }
    if (isAppointmentApprovalStatusPending) {
      return 'Awaiting Approval';
    }

    return APPOINTMENT_STATUS_USER_DISPLAY[status];
  }, [
    isCancelled,
    isAppointmentApprovalStatusPending,
    isAppointmentApprovalStatusDeclined,
    status,
  ]);

  const badgeType = useMemo(() => {
    if (isAppointmentApprovalStatusDeclined) {
      return 'red' as BadgeType;
    }
    if (isCancelled) {
      return APPOINTMENT_STATUS_BADGE_COLOR[status] as BadgeType;
    }
    if (isAppointmentApprovalStatusPending) {
      return 'grey' as BadgeType;
    }

    return APPOINTMENT_STATUS_BADGE_COLOR[status] as BadgeType;
  }, [
    isCancelled,
    isAppointmentApprovalStatusPending,
    isAppointmentApprovalStatusDeclined,
    status,
  ]);

  return (
    <div
      className={clsxMerge(
        'flex flex-col justify-between divide-y divide-neutral-300 rounded-lg border border-neutral-300 bg-neutral-50 p-4 text-left shadow-providerCard sm:p-6',
        shouldHideFooterAction && 'cursor-default',
      )}
      data-cy="appointment-card"
      onClick={handleClick}
    >
      <div
        className={clsxMerge('flex h-full w-full flex-col justify-between gap-y-4', {
          'pb-4': Boolean(paymentDetails),
        })}
      >
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium text-primary-500">{appointmentSchedule}</span>
            {showBadge && (
              <Badge
                type={badgeType}
                className={
                  isAppointmentApprovalStatusPending && !isCancelled ? 'lg:text-[9.5px]' : ''
                }
              >
                {appointmentStatusDisplay}
              </Badge>
            )}
          </div>
          <div className="flex flex-1 flex-col gap-y-3">
            <p className="font-medium" data-cy="appointment-services">
              {services}
            </p>
            <div className="flex gap-x-2">
              <MapPinIcon className="h-5 w-5 flex-shrink-0 text-[#4499D7]" />
              <div>
                <p className="text-sm font-medium" data-cy="appointment-provider-name">
                  {providerName}
                </p>
                <p className="text-xs leading-tight text-graniteGray">{providerAddress}</p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex gap-x-2">
                <UserCircleIcon className="h-5 w-5 flex-shrink-0 text-[#4499D7]" />
                <p className="text-sm font-medium">{numberOfClientsBooked}</p>
              </div>
              {clientProfileIds.length >= 2 && SHOW_GROUP_BOOKING && (
                <div className="ml-7 mt-1">
                  <ListSelectedClientGroupBooking clientProfileIds={clientProfileIds} />
                </div>
              )}
            </div>
          </div>
        </div>
        <ActionFooter
          appointmentId={appointmentId}
          branchId={branchId}
          hasFeedback={hasFeedback}
          shouldHideFooterAction={shouldHideFooterAction}
          status={status}
          handleClickViewDetails={handleClick}
        />
      </div>
      {paymentDetails && (
        <BookingPaymentDetails
          shouldShowAmount
          className="flex flex-col gap-y-3 pt-4"
          paymentDetails={paymentDetails}
        />
      )}
    </div>
  );
}

function ActionFooter(props: ActionFooterProps) {
  const {
    appointmentId,
    branchId,
    hasFeedback,
    shouldHideFooterAction,
    status,
    handleClickViewDetails,
  } = props;
  const router = useRouter();

  if (shouldHideFooterAction) {
    return null;
  }

  if (SHOW_FEEDBACK_FEATURE) {
    const handleClickBookAgain = (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();

      router.push(Routes.PROVIDER_SERVICE_LIST.replace(':id', branchId ?? ''));
    };

    const handleClickShareFeedback = (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();

      router.push(Routes.APPOINTMENT_FEEDBACK.replace(':id', appointmentId ?? ''));
    };

    if (status === AppointmentStatusEnum.Completed) {
      return (
        <div className="flex w-full gap-2">
          <Button
            className={clsxMerge(
              'flex-1 rounded-full py-2 text-sm md:text-xs',
              !hasFeedback && 'text-xs',
            )}
            variant="primary"
            onClick={handleClickBookAgain}
          >
            Book Again
          </Button>
          {!hasFeedback && (
            <Button
              className="flex-1 rounded-full border-primary-500 bg-transparent py-2 text-xs"
              variant="secondary"
              onClick={handleClickShareFeedback}
            >
              Share Feedback
            </Button>
          )}
        </div>
      );
    }
  }

  return (
    <div className="flex w-full">
      <Button
        className="w-full rounded-full border-primary-500 bg-transparent py-2 text-sm md:text-xs"
        variant="secondary"
        onClick={handleClickViewDetails}
      >
        View Details
      </Button>
    </div>
  );
}
