import React, { Fragment } from 'react';

import DescriptionCard from './DescriptionCard';
import { DESCRIPTION_CARD_DATA } from '../../constants/descriptionCardData';

const DescriptionCards = () => {
  return (
    <Fragment>
      <div className="flex w-full flex-col gap-y-8 xl:gap-y-[60px]">
        <h1 className="text-2xl  font-semibold text-neutral-600 lg:text-[32px] ">
          Why choose HealthHub
        </h1>
        <div className="flex items-start justify-between gap-x-8">
          {DESCRIPTION_CARD_DATA.map((data, index) => (
            <DescriptionCard
              key={index}
              imageUrl={data.imageUrl}
              title={data.title}
              description={data.description}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default DescriptionCards;
