import { HTMLAttributes } from 'react';

import {
  TransactionExternalPaymentStatusEnum,
  TransactionPaymentOptionEnum,
} from '@healthhub/api-lib';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import {
  DATE_TIME_WITH_DOT,
  PAYMENT_VIA,
  convertDateByFormat,
  formatToPeso,
} from '@mwell-healthhub/commons';
import ExternalPaymentStatusBadge from '@mwell-healthhub/commons/components/ExternalPaymentStatusBadge';

import { BookingPaymentDetailsPropValues } from '../../contexts/provider';

interface Props extends HTMLAttributes<HTMLDivElement> {
  paymentDetails: BookingPaymentDetailsPropValues | null;
  shouldShowAmount?: boolean;
}

export default function BookingPaymentDetails({
  paymentDetails,
  shouldShowAmount,
  className,
}: Props) {
  const date = paymentDetails?.paymentDate ?? paymentDetails?.transactionDate;

  return (
    <div className={className}>
      <span className="font-medium text-neutral-600">Payment Details</span>
      {shouldShowAmount && (
        <div className="flex flex-row flex-nowrap items-center justify-between">
          <span className="text-sm text-neutral-500">Amount</span>
          <span className="text-right text-sm font-medium text-neutral-600">
            {paymentDetails?.totalAmountDue ? formatToPeso(paymentDetails?.totalAmountDue) : '-'}
          </span>
        </div>
      )}
      <div className="flex flex-row flex-nowrap items-center justify-between">
        <span className="text-sm text-neutral-500">Status</span>
        <span className="text-sm font-medium uppercase text-neutral-600">
          {paymentDetails?.paymentStatus === TransactionExternalPaymentStatusEnum.Success ? (
            <div className="flex flex-row gap-x-1">
              <CheckCircleIcon width={20} height={20} className="text-green-700" />
              <span className="text-sm font-medium capitalize text-neutral-600">Paid</span>
            </div>
          ) : (
            <ExternalPaymentStatusBadge
              paymentStatus={paymentDetails?.paymentStatus}
              paymentMethod={paymentDetails?.paymentMethod}
            />
          )}
        </span>
      </div>
      <div className="flex flex-row flex-nowrap items-center justify-between">
        <span className="text-sm text-neutral-500">Payment Time</span>
        <span className="text-sm font-medium text-neutral-600">
          {date ? convertDateByFormat(date, DATE_TIME_WITH_DOT) : '-'}
        </span>
      </div>
      <div className="flex flex-row flex-nowrap items-center justify-between">
        <span className="text-sm text-neutral-500">Transaction No.</span>
        <span className="text-right text-sm font-medium text-neutral-600">
          {paymentDetails?.transactionNo ?? '-'}
        </span>
      </div>
      <div className="flex flex-row flex-nowrap items-center justify-between">
        <span className="text-sm text-neutral-500">Payment Method</span>
        <span className="text-sm font-medium text-neutral-600">
          {PAYMENT_VIA[paymentDetails?.paymentMethod as TransactionPaymentOptionEnum] ??
            paymentDetails?.paymentMethod}
        </span>
      </div>
    </div>
  );
}
