import React from 'react';

import dynamic from 'next/dynamic';

const Carousel = dynamic(() => import('./Carousel'), {
  ssr: false,
  loading: () => <div />,
});

const Advertisement = () => {
  return (
    <div className="rounded-lg shadow-providerCard">
      <Carousel />
    </div>
  );
};

export default Advertisement;
