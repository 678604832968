import { HTMLProps } from 'react';

import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { Icon } from '@mwell-healthhub/commons';

import {
  LOGGED_IN_BOTTOM_NAVS,
  NOT_LOGGED_IN_BOTTOM_NAVS,
  NavData,
} from '../../../constants/bottomNav';

type Props = HTMLProps<HTMLDivElement> & {
  activeTab: string;
  isUserLoggedIn: boolean;
};

function BottomNavigation({ activeTab, className, isUserLoggedIn }: Props) {
  const bottomNavs = isUserLoggedIn ? LOGGED_IN_BOTTOM_NAVS : NOT_LOGGED_IN_BOTTOM_NAVS;

  const navigationList = bottomNavs
    .filter((tab) => !tab.hidden)
    .map((tab: NavData) => {
      const { icon, inactiveIcon, label, name, url } = tab;
      const isTabActive = activeTab === name;

      const navItemClass = twMerge(
        'min-w-16 flex w-1/4 cursor-pointer flex-col items-center gap-1 rounded-lg p-2 py-1',
        isTabActive ? 'bg-skyBlue' : 'bg-transparent',
      );

      const navItemLabelClass = twMerge(
        'text-[10px] font-medium',
        isTabActive ? 'text-primary2' : 'text-primary200',
      );

      const navIcon = isTabActive ? icon : inactiveIcon;

      return (
        <Link href={url} key={name} className={navItemClass} data-cy={`${name}-navigation`}>
          <Icon image={navIcon as string} />
          <span>
            <h2 className={navItemLabelClass}>{label}</h2>
          </span>
        </Link>
      );
    });

  return (
    <div className={twMerge('flex-shrink-none fixed bottom-0 z-10 w-full bg-white', className)}>
      <div className="flex w-full justify-between border-t-[1px] border-skyBlue p-2">
        {navigationList}
      </div>
    </div>
  );
}

export default BottomNavigation;
