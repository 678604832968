import Link from 'next/link';

import { Icon } from '@mwell-healthhub/commons';

import { SERVICES } from '../../../constants/service';

const ServiceAction = () => {
  return (
    <div className="grid grid-cols-3 gap-4">
      {SERVICES.map((service) => (
        <Link
          key={service.name}
          className="flex flex-col items-center"
          href={service.link}
          data-cy="provider-card"
        >
          <div className="ro mb-1 flex w-full justify-center rounded-2xl rounded-tr-none bg-serviceIcon p-4">
            <Icon image={service.icon} height={52} width={52} />
          </div>
          <span className="text-xs font-medium capitalize text-primary-500">{service.label}</span>
        </Link>
      ))}
    </div>
  );
};

export default ServiceAction;
