import React, { Fragment, useState } from 'react';

import { filter } from 'lodash';

import { MultiSelectOptionType, useRouter } from '@mwell-healthhub/commons';

import Advertisement from './Advertisement';
import { Routes } from '../../../constants';
import { HmoFilterBadges } from '../../molecules';
import DescriptionCards from '../../molecules/DescriptionCards';
import DownloadCard from '../../molecules/DownloadCard';
import HomeSearch from '../../molecules/HomeSearch';
import PromotionCard from '../../molecules/PromotionCard';
import ServiceCards from '../../molecules/ServiceCards';

const HomeContent = () => {
  const router = useRouter();
  const { query } = router;
  const [hmoFilters, setHmoFilters] = useState<MultiSelectOptionType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValue = event.target.value;

    setSearchTerm(newValue);
  }

  const handleApplyHmoFilters = (selectedHmoFilters: MultiSelectOptionType[]) => {
    if (selectedHmoFilters === hmoFilters) {
      return;
    }
    setHmoFilters(selectedHmoFilters);
    delete query.path;

    if (searchTerm === '') {
      router.replace({
        pathname: Routes.PROVIDERS,
        query: { ...query, hmoFilters: selectedHmoFilters.map(({ value }) => value) },
      });
    } else {
      router.replace({
        pathname: Routes.PROVIDERS,
        query: {
          ...query,
          query: searchTerm,
          hmoFilters: selectedHmoFilters.map(({ value }) => value),
        },
      });
    }
  };

  const onRemoveHmoFilter = (hmo: MultiSelectOptionType) => {
    let updatedHmoFilters: MultiSelectOptionType[] = [];
    setHmoFilters((hmoFilters) => {
      updatedHmoFilters = filter(hmoFilters, ({ value }) => value !== hmo.value);
      handleApplyHmoFilters(updatedHmoFilters);
      return updatedHmoFilters;
    });
  };

  return (
    <Fragment>
      <div className="flex flex-col items-center justify-center">
        <div className="flex=col flex w-full items-center justify-center bg-primary-100 bg-opacity-10">
          <div className="-mt-[42px] w-[555px] bg-transparent md:w-[700px] lg:w-[925px] xl:w-[1110px]">
            <HomeSearch
              appliedHmoFilters={hmoFilters}
              handleApplyHmoFilters={handleApplyHmoFilters}
              onChange={handleChangeSearch}
            />
            {!!hmoFilters.length && (
              <div className="flex w-full justify-center py-7">
                <div className="flex w-[1110px] flex-col gap-y-8 px-10 xl:px-0">
                  <HmoFilterBadges
                    appliedHmoFilters={hmoFilters}
                    onRemoveHmoFilter={onRemoveHmoFilter}
                  />
                </div>
              </div>
            )}
            <ServiceCards />
          </div>
        </div>
        <div className="mt-[38px] w-[555px] bg-transparent md:w-[700px] lg:w-[925px] xl:w-[1110px]">
          <div className="mb-10 xl:mb-[61px]">
            <DescriptionCards />
          </div>
          <div className="mb-10 xl:mb-[61px]">
            <Advertisement />
          </div>
          <div className="mb-10 xl:mb-[60px]">
            <PromotionCard />
          </div>
          <div className="mb-10 xl:mb-[60px]">
            <DownloadCard />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HomeContent;
