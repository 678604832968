import { useState } from 'react';

import { User } from '@healthhub/api-lib';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { toast, getLocalStorageItem, useUserVerificationMutation } from '@mwell-healthhub/commons';
import { Button } from '@mwell-healthhub/components/v2';

const VerifyEmail = () => {
  const user = getLocalStorageItem('user') as User;
  const { mutate, isLoading } = useUserVerificationMutation({
    onSuccess: () => {
      setIsHidden(true);

      toast({
        message: 'Email verification link sent',
        type: 'success',
        alertPosition: 'top-center',
      });
    },
    onError: () => {
      toast({
        message: 'Oops! Something went wrong.',
        type: 'error',
        alertPosition: 'top-center',
      });
    },
  });

  const [isHidden, setIsHidden] = useState<boolean>(false);

  const handleHide = () => {
    setIsHidden(true);
  };

  const handleVerifyEmail = () => {
    mutate(user?.email);
  };

  if (isHidden) {
    return null;
  }

  return (
    <div className="w-full p-4 pb-0">
      <div className="rounded-lg border border-primary-500 p-3">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium">Verify your email address</h3>
          <button type="button" onClick={handleHide}>
            <XMarkIcon className="h-6 w-6 flex-shrink-0 text-primary-500" />
          </button>
        </div>
        <p className="mb-3 text-xs text-gray41">
          This helps us know that you own this email account
        </p>
        <Button
          className="w-full rounded-full border-none disabled:bg-primary-500/50"
          isLoading={isLoading}
          variant="primary"
          onClick={handleVerifyEmail}
        >
          <span className="text-xs font-medium text-white">Verify Email</span>
        </Button>
      </div>
    </div>
  );
};

export default VerifyEmail;
