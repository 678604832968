import { Promotion, ProviderStatusEnum } from '@healthhub/api-lib';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button, MAX_PROMOTION_COUNT, useRouter } from '@mwell-healthhub/commons';

import ProviderCard from './ProviderCard';
import { Routes } from '../../constants/routes';
import { useIsMobile } from '../../hooks';
import { useGetAllPublishedPromotions } from '../../hooks/queries/promotionQueries';

export default function PromotionCard() {
  const router = useRouter();
  const { data: promotions, isFetching: isFetchingPromotions } = useGetAllPublishedPromotions();
  const isMobile = useIsMobile();

  if (isFetchingPromotions) {
    return <div></div>;
  }

  const filteredPromotions = promotions?.filter((promotion) => {
    const publishedProviders = promotion.providers.filter(
      (provider) =>
        provider.status === ProviderStatusEnum.Published &&
        provider.parentProvider?.status === ProviderStatusEnum.Published,
    );

    return publishedProviders.length > 0;
  });

  const sortedPromotions = filteredPromotions?.sort((a, b) => a.name.localeCompare(b.name));

  function handleSeeMore(promotion: Promotion) {
    router.push(Routes.PROMOTION.replace(':id', promotion.id.toString()));
  }

  const mobileView = (
    <>
      {sortedPromotions?.map((promotion) => (
        <div key={promotion.id}>
          <div className="flex w-full items-center justify-between">
            <h2 className="text-md mb-2 font-semibold text-gray-900">{promotion.name}</h2>
            {promotion.providers.length > MAX_PROMOTION_COUNT && (
              <Button
                className="p-0 text-primary-500 underline"
                variant="plain"
                onClick={() => handleSeeMore(promotion)}
              >
                See more
              </Button>
            )}
          </div>
          <div>
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              className="rounded-lg"
              autoplay={{ delay: 5000 }}
            >
              {[...promotion.providers].splice(0, MAX_PROMOTION_COUNT).map((provider, index) => (
                <SwiperSlide key={index} onClick={() => handleSeeMore(promotion)}>
                  <div className="mb-8">
                    <ProviderCard key={provider.id} provider={provider} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ))}
    </>
  );

  const desktopView = (
    <>
      {sortedPromotions?.map((promotion) => (
        <div key={promotion.id} className="mb-10 xl:mb-[60px]">
          <h2 className="mb-8 text-2xl font-semibold text-gray-900">{promotion.name}</h2>
          <div className="grid grid-cols-3 gap-2">
            {[...promotion.providers].splice(0, MAX_PROMOTION_COUNT).map((provider) => (
              <div key={provider.id} className="flex w-full">
                <ProviderCard provider={provider} />
              </div>
            ))}
          </div>
          {promotion.providers.length > MAX_PROMOTION_COUNT && (
            <div className="mt-8 flex w-full justify-center">
              <Button
                className="max-w-[320px] flex-1"
                variant="outlinedPrimary"
                onClick={() => handleSeeMore(promotion)}
              >
                See more
              </Button>
              <div></div>
            </div>
          )}
        </div>
      ))}
    </>
  );

  return isMobile ? mobileView : desktopView;
}
