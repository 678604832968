import React, { Fragment } from 'react';

const BookingHeader = () => {
  const dots = '/static-assets/images/dots.svg';
  const DentalImage = '/static-assets/images/Dental.jpg';
  const SkinImage = '/static-assets/images/Skin.webp';
  const WellnessImage = '/static-assets/images/Wellness.webp';
  const EyesImage = '/static-assets/images/Eyes.jpg';

  return (
    <Fragment>
      <div className="flex w-full items-center justify-center gap-x-10 overflow-hidden bg-ad-background-gradient pb-20 pt-10 md:h-[472px] lg:gap-x-0">
        <div className="flex w-[555px] flex-row items-center justify-between md:w-[700px] lg:w-[925px] xl:w-[1110px]">
          <div className="flex w-[540px] flex-col gap-y-4">
            <h1 className="font-semibold text-secondary-100 sm:text-3xl md:text-4xl xl:text-5xl">
              Book <span className="text-secondary-500">Health & Wellness Providers</span> In Your
              Area
            </h1>
            <p className="text-lg text-secondary-300 xl:text-xl">
              Get access to specialized health and wellness services in your area and easily
              schedule appointments.
            </p>
          </div>
          <div className="justify-end sm:hidden md:hidden lg:flex">
            <div className="relative flex h-[350px] gap-3">
              <div
                className="absolute bottom-[-80px] left-[-25px] h-[90px] w-[90px] bg-auto bg-no-repeat"
                style={{ backgroundImage: `url(${dots})` }}
              />
              <div
                className="absolute right-[-72px] top-[-24px] h-[90px] w-[90px] bg-auto bg-no-repeat"
                style={{ backgroundImage: `url(${dots})` }}
              />
              <div className="flex w-[276px] flex-col items-end gap-3">
                <div
                  className="h-[160px] w-[276px] rounded-md bg-cover bg-local bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${WellnessImage})` }}
                ></div>
                <div
                  className="h-[154px] w-[252px] rounded-md bg-cover bg-local bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${SkinImage})` }}
                ></div>
              </div>
              <div className="flex w-[241px] flex-wrap content-end items-start gap-3">
                <div
                  className="h-[108px] w-[241px] rounded-md bg-cover bg-local bg-top bg-no-repeat"
                  style={{ backgroundImage: `url(${EyesImage})` }}
                ></div>
                <div
                  className="h-[206px] w-[217px] rounded-md bg-cover bg-local bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${DentalImage})` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="sm:hidden md:flex lg:hidden">
            <div className="relative flex h-[350px] gap-3">
              <div
                className="absolute bottom-[-80px] left-[-25px] h-[90px] w-[90px] bg-auto bg-no-repeat"
                style={{ backgroundImage: `url(${dots})` }}
              />
              <div
                className="absolute right-[-22px] top-[-24px] h-[90px] w-[90px] bg-auto bg-no-repeat"
                style={{ backgroundImage: `url(${dots})` }}
              />
              <div className="flex w-[226px] flex-col items-end gap-3">
                <div
                  className="h-[160px] w-[226px] rounded-md bg-cover bg-local bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${WellnessImage})` }}
                ></div>
                <div
                  className="h-[154px] w-[212px] rounded-md bg-cover bg-local bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${SkinImage})` }}
                ></div>
              </div>
              <div className="flex w-[191px] flex-wrap content-end items-start gap-3">
                <div
                  className="h-[108px] w-[191px] rounded-md bg-cover bg-local bg-top bg-no-repeat"
                  style={{ backgroundImage: `url(${EyesImage})` }}
                ></div>
                <div
                  className="h-[206px] w-[167px] rounded-md bg-cover bg-local bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${DentalImage})` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BookingHeader;
