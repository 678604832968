import { Appointment } from '@healthhub/api-lib';
import { format } from 'date-fns';

import {
  DATE_TIME_FORMAT_WITH_SINGLE_DIGIT_HOUR,
  EVENT_DATE_TIME_FORMAT,
  Icon,
  displayAppointmentDateTime,
  formatCompleteAddress,
  formatUserNameToFull,
  useRouter,
} from '@mwell-healthhub/commons';

import { Routes } from '../../../constants';
import { getProviderServicesLabelFromAppointment } from '../../../utils/appointment';
import AppointmentDetailCard from '../../molecules/AppointmentDetailCard';

type Props = {
  appointment?: Appointment | null;
};

const NextAppointment = ({ appointment }: Props) => {
  const router = useRouter();

  let appointmentDisplay = (
    <div className="flex flex-col items-center rounded-lg border border-gray39 p-4 text-center">
      <Icon image="/static-assets/images/no-appointment.png" height={85} width={95} />
      <span className="mt-4 text-sm text-tertiaryBlue4">
        You don&apos;t have any scheduled appointments.
      </span>
    </div>
  );

  const handleAppointmentDetailsClick = () => {
    router.push(Routes.APPOINTMENT_DETAILS.replace(':id', appointment?.id?.toString() ?? ''));
  };

  if (appointment) {
    const fomattedBookedDate = displayAppointmentDateTime(
      appointment.dateTime,
      DATE_TIME_FORMAT_WITH_SINGLE_DIGIT_HOUR,
    );

    appointmentDisplay = (
      <AppointmentDetailCard
        appointmentId={appointment?.id.toString()}
        appointmentSchedule={fomattedBookedDate}
        approvalStatus={appointment.approvalStatus}
        branchId={appointment.provider?.id.toString()}
        hasFeedback={Boolean(appointment.feedbackId)}
        patientName={formatUserNameToFull(
          appointment.providerClientProfile ?? appointment.clientProfile,
        )}
        providerAddress={formatCompleteAddress(appointment.provider?.address)}
        providerName={appointment.provider?.displayName ?? ''}
        services={getProviderServicesLabelFromAppointment(appointment)}
        status={appointment.status}
        onClick={handleAppointmentDetailsClick}
      />
    );
  }

  return (
    <div>
      <h3 className="mb-4 font-medium">Your Upcoming Appointment</h3>
      {appointmentDisplay}
    </div>
  );
};

export default NextAppointment;
